<template>
  <div>
    <Preloader :loading="isLoading" />
    <div v-if="submission && !isLoading" class="padding-x speaker">
      <div class="speaker-main">
        <Heading noDecoration>
          {{ submission.bio.first_name }} {{ submission.bio.last_name }}
        </Heading>

        <p class="py-5">{{ submission.bio.job_title }}</p>
        <i>{{ submission.bio.organization }}</i>
      </div>

      <div
        v-show="submission.bio.image"
        class="speaker-img"
        :style="`background-image: url(${submission.bio.image})`"
      >
        <div v-show="submission.is_keynote" class="badge">Keynote Speaker</div>
      </div>
      <div class="speaker-bio">
        <b
          v-show="
            submission.bio.social_media && submission.bio.social_media.length
          "
        >
          Connect:
        </b>
        <SocialMedia :social="submission.bio.social_media" class="mt-3" />
        <hr />
        <b class="mt-3">{{ submission.talk.title }}</b>
        <div>{{ submission.talk.description }}</div>
        <hr />
        <b v-show="submission.bio.bio && submission.bio.bio.length">Bio</b>
        <div v-html="submission.bio.bio" />
      </div>
    </div>
    <!--          for additional Speakers -->
    <div v-if="submission?.add_bios && submission.add_bios.length">
      <div
        v-for="addSpeaker in submission.add_bios"
        :key="addSpeaker.id"
        class="padding-x pt-0 speaker mt-8"
      >
        <div class="speaker-main">
          <Heading noDecoration>
            {{ addSpeaker.first_name }} {{ addSpeaker.last_name }}
          </Heading>

          <p class="py-5">{{ addSpeaker.job_title }}</p>
          <i>{{ addSpeaker.organization }}</i>
        </div>

        <div
          v-show="addSpeaker.image"
          class="speaker-img"
          :style="`background-image: url(${addSpeaker.image})`"
        />
        <div class="speaker-bio">
          <b v-show="addSpeaker.social_media && addSpeaker.social_media.length">
            Connect:
          </b>
          <SocialMedia :social="addSpeaker.social_media" class="mt-3" />
          <hr />
          <b v-show="addSpeaker.bio && addSpeaker.bio.length">Bio</b>
          <div v-html="addSpeaker.bio" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
import SocialMedia from "@/components/common/SocialMedia";

export default {
  name: "Speaker",
  props: {},
  components: {
    SocialMedia,
    Preloader,
    Heading,
  },
  data: () => ({
    isLoading: false,
    submission: null,
  }),
  mounted() {
    this.isLoading = true;
    this.getSubmission();
  },
  methods: {
    ...mapActions(["GET"]),
    getSubmission() {
      return this.GET({
        route: `public/event/${this.$route.params.id}/submission/${this.$route.params.speaker_id}`,
      })
        .then((resp) => {
          this.submission = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.speaker {
  padding-top: 108px;
  display: grid;
  gap: 25px;
  grid-template-columns: 2fr 1fr;
  align-items: end;

  &:after {
    content: "";
    background: $light-gray;
    height: 100vh;
    width: 25%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
  }

  &-main {
    font-family: $sarabun;
    font-weight: $sarabunBold;
    font-size: 32px;
    line-height: 32px;
    color: $dark-gray;
  }

  &-bio {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: justify;
  }

  &-img {
    width: 100%;
    height: 180px;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    position: relative;

    .badge {
      background-color: #daecff;
      text-align: end;
      padding: 0 20px;
      height: 350px;
      width: 110%;
      position: absolute;
      top: -20%;
      left: 5%;
      color: $black;
      font-size: 16px;
      font-weight: 600;
      z-index: -1;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 140px;
    &:after {
      height: 50vh;
    }
  }

  @media (min-width: $media-md) {
    padding-top: 155px;
    &-img {
      height: 300px;

      .badge {
        top: -13%;
        font-size: 20px;
      }
    }
  }

  @media (min-width: $media-lg) {
    padding-top: 170px;
  }

  @media (min-width: $media-xl) {
    &:after {
      height: 100vh;
    }
    &-bio {
      grid-column-end: 2;
    }
  }
}
</style>
